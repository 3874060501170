import App from './App';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import 'regenerator-runtime/runtime';
import * as Sentry from '@sentry/react';

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'hml') {
	Sentry.init({
		dsn: 'https://6a5e98ecc70989068c10d9abe2df2a23@o4505621957771264.ingest.sentry.io/4506819316416512',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

const container = document.getElementById('root');
if (container) {
	const root = createRoot(container);
	root.render(
		// eslint-disable-next-line no-undef
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<App />
		</BrowserRouter>
	);
}
