export const UserRoleEnum = {
	Admin: 'Admin',
	Store: 'Store',
};
export const UserRoleList = Object.values(UserRoleEnum);

export const ProductSizeEnum = {
	PP: 'PP',
	P: 'P',
	M: 'M',
	G: 'G',
	GG: 'GG',
	U: 'U',
};
export const ProductSizeList = Object.values(ProductSizeEnum);

export const OrderStatusEnum = {
	DealProcess: 'em_conversa',
	Unpaid: 'aguardando_pagamento',
	Paid: 'aguardando_envio',
	Send: 'a_caminho',
	Delivered: 'entregue',
	Finished: 'finalizado',
	Canceled: 'cancelado',
};

export const OrderStatusList = Object.values(OrderStatusEnum);

export const MembershipStatusEnum = {
	Approved: 'Aprovado',
	Disapproved: 'Reprovado',
	Evaluation: 'Para avaliação',
};

export const MembershipStatusList = Object.values(MembershipStatusEnum);

export const OrderStatusSuccessList = Object.values(OrderStatusEnum).filter(
	(item) => ![OrderStatusEnum.Canceled].includes(item)
);

export const OrderPaymentStatusEnum = {
	Paid: 'Paid',
	PaymentFailed: 'PaymentFailed',
	Unpaid: 'Unpaid',
	AwaitingAuthorization: 'AwaitingAuthorization',
};
export const OrderPaymentStatusList = Object.values(OrderPaymentStatusEnum);

export const OrderDiscountTypeEnum = {
	coin: 'coin',
	voucher: 'voucher',
	coupon: 'coupon',
};
export const OrderDiscountTypeList = Object.values(OrderPaymentStatusEnum);

export const EmployeeStatusEnum = {
	aguardando_preenchimento: 'Colaborador Comunicado',
	conta_enviada: "Conta Enviada",
	correcao_solicitada: 'Correção Solicitada',
	aprovado: 'Aprovado',
	reprovado: 'Reprovado',
};

export const EmployeeStatusAdminEnum = {
	aguardando_conexao: 'Aguardando Conexão',
	conectado_usina: "Conectado a Usina",
};

export const getStatusAdminDescription = (key: string) => {
	if (key === 'aguardando_conexao') return EmployeeStatusAdminEnum.aguardando_conexao;
	if (key === 'conectado_usina') return EmployeeStatusAdminEnum.conectado_usina;
	return '';
}

export const EmployeeStatusEnumKeys = Object.keys(EmployeeStatusEnum);
