import { useAuthContext, useThemeContext } from '@/common';
import { ThemeSettings } from '@/config/menu';
import { lazy } from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
import VerticalLayout from '@/layouts/Vertical';
import HorizontalLayout from '@/layouts/Horizontal';
import Root from './Root';
import DefaultLayout from '@/layouts/Default';

const Admin = lazy(() => import('../pages/admin'));
const Cliente = lazy(() => import('../pages/cliente'));
const Login = lazy(() => import('../pages/account/Login'));
const RecoverPassword = lazy(() => import('../pages/account/RecoverPassword'));
const Logout = lazy(() => import('../pages/account/Logout'));

export default function ProtectedRoutes() {
	const { settings } = useThemeContext();
	const Layout =
		settings.layout.type == ThemeSettings.layout.type.vertical
			? VerticalLayout
			: HorizontalLayout;

	const { user } = useAuthContext();

	return user ? (
		<ReactRoutes>
			<Route path="/admin/*" element={<Layout />}>
				<Route index element={<Root />} />
				<Route path="logout" element={<Logout type="Admin" />} />
				<Route path="login" element={<Login type="Admin" />} />
				<Route path="*" element={<Admin />} />
			</Route>
			<Route path="/cliente/*" element={<Layout />}>
				<Route index element={<Root />} />
				<Route path="logout" element={<Logout type="Client" />} />
				<Route path="login" element={<Login type="Client" />} />
				<Route path="*" element={<Cliente />} />
			</Route>
			<Route path="/*" element={<Layout />}>
				<Route index element={<Root />} />
				<Route path="*" element={<Cliente />} />
			</Route>
		</ReactRoutes>
	) : (
		<ReactRoutes>
			<Route path="/*" element={<DefaultLayout />}>
				<Route index element={<Login type="Client" />} />
				<Route path="admin/login" element={<Login type="Admin" />} />
				<Route path="admin/logout" element={<Logout type="Admin" />} />
				<Route path="admin/recover-password" element={<RecoverPassword type="Admin" />} />
				<Route
					path="cliente/recover-password"
					element={<RecoverPassword type="Client" />}
				/>
				<Route path="cliente" element={<Login type="Client" />} />
				<Route path="cliente/login" element={<Login type="Client" />} />
				<Route
					path="cliente/recover-password"
					element={<RecoverPassword type="Client" />}
				/>
				<Route path="cliente/logout" element={<Logout type="Client" />} />
				<Route path="admin/*" element={<Login type="Admin" />} />
				<Route path="*" element={<Login type="Client" />} />
			</Route>
		</ReactRoutes>
	);
}
