import { ConfirmData } from '@/types/ConfirmData';
import UseTerms from './UseTerms';
import { FC, useEffect, useState } from 'react';
import Form from '../preenchimento/Form';
import useConfirmData from './hooks/useConfirmData';
import { useParams } from 'react-router-dom';

interface Props {
	update?: boolean;
}

const Confirmacao: FC<Props> = ({ update = false }) => {
	const [data, setData] = useState<ConfirmData>();
	const [loading, setLoading] = useState(true);

	const confirmForm = (confirmData: ConfirmData) => {
		setData(confirmData);
	};

	const { getDataToEdit, defaultValues } = useConfirmData();
	const { token } = useParams<{ token: string }>();

	useEffect(() => {
		if (update && token) {
			getDataToEdit(token);
		}
	}, [token]);

	useEffect(() => {
		setData(defaultValues);
	}, [defaultValues]);

	useEffect(() => {
		if (data) setLoading(false);
	}, [data]);

	return (
		<>
			{update ? (
				<div className="container-preenchimento">
					{data ? (
						<Form employee={data} token={token} update={update} />
					) : loading ? (
						<tr>
							<td className="text-center" colSpan={100}>
								<div className="bouncing-loader">
									<div></div>
									<div></div>
									<div></div>
								</div>
							</td>
						</tr>
					) : (
						<p>Erro ao carregar dados</p>
					)}
				</div>
			) : (
				<>
					{!data ? (
						<div className="container-use-terms">
							<UseTerms confirmData={(e) => e && confirmForm(e)} />
						</div>
					) : (
						<div className="container-preenchimento">
							{data ? (
								<Form token={token} employee={data} />
							) : loading ? (
								<tr>
									<td className="text-center" colSpan={100}>
										<div className="bouncing-loader">
											<div></div>
											<div></div>
											<div></div>
										</div>
									</td>
								</tr>
							) : (
								<p>Erro ao carregar dados</p>
							)}
						</div>
					)}
				</>
			)}
		</>
	);
};

export default Confirmacao;
