import { HttpClient } from '@/data';

const config = {
	headers: {
		Authorization: null,
	},
};

export const GovRepository = {
	get api() {
		return HttpClient();
	},
	cidades: async function (uf: string) {
		return this.api.get(this.api.endpoints.cidades(uf), config);
	},
};
