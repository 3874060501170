// import Logo from '../../../assets/images/logoMaedeDeus.png';
import { FC, useState } from 'react';
import kiiry_svg from '../../../assets/images/logo.png';
import FormConfirm from './formConfirm';
import { ConfirmData } from '@/types/ConfirmData';

interface Props {
	confirmData?: (data?: ConfirmData) => void;
}
	
const UseTerms: FC<Props> = ({ confirmData = () => {} }) => {
	const [layoutValues, setLayoutValues] = useState<ConfirmData>();

	return (
		<div className="content-use-terms">
			<div className="img-principal">
				{layoutValues?.logo ? (
					<img className="logo" src={layoutValues.logo} alt="logo" />
				) : (
					<img className="logo" src={kiiry_svg} alt="logo" />
				)}
			</div>
			<div className="formulario-use-terms">
				<div style={{ alignSelf: 'start', margin: '0 10%', width: '80%', overflow: 'hidden' }}>
					<p>Que bom que você se interessou pelo benefício! Para saber mais sobre o funcionamento siga os passos:</p>
					<p>
						PASSO 1: saiba tudo sobre o BENEFÍCIO clicando aqui:{' '}
						<a href={layoutValues?.terms_link_url} target="_blank" rel="noopener noreferrer">{layoutValues?.terms_link_url}</a>
					</p>
					<p>PASSO 2: preencha os dados solicitados abaixo e finalize seu cadastro.</p>
					<p>PASSO 3: ENVIE AS CONTAS e comece a economizar dinheiro tendo descontos todos os meses nas contas da Cemig.</p>
				</div>
				<div className="campos_inputs">
					<h4>
						Para receber o benefício, informe os dados abaixo
					</h4>
					<div className="inputs_container">
						<FormConfirm
							confirmData={(e) => {
								confirmData(e);
							}}
							layoutValues={(e) => {
								setLayoutValues(e);
							}}
						/>
					</div>
					<div>
						<span className="logo-lg">
							<img src={kiiry_svg} />
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};
export default UseTerms;
