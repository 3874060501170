import { ApiResponse, DbId } from '@/types';
import { HttpClient } from '@/data';
import { Employee, EmployeeFilter, EmployeeList } from '@/types/Gestao';

const configPublic = {
	headers: {
		Authorization: null,
	},
};

export const EmployeeRepository = {
	get api() {
		return HttpClient();
	},

	list: async function (
		params: EmployeeFilter & { limit?: number; offset?: number } = {}
	): Promise<ApiResponse<EmployeeList>> {
		const { limit, offset, ...filtro } = params;
		let config = {
			params: {
				...filtro,
				limit,
				offset,
			},
		};
		return this.api.get(`${this.api.endpoints.employee}/`, config);
	},

	create: async function (data: Partial<FormData>): Promise<ApiResponse<Employee>> {
		const config = {
			...configPublic,
			headers: {
				...configPublic.headers,
				'Content-Type': 'multipart/form-data',
			},
		};
		return this.api.post(`${this.api.endpoints.employee}`, data, config);
	},

	update: async function (id: number, data: Partial<FormData>): Promise<ApiResponse<Employee>> {
		let config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};
		return this.api.patch(`${this.api.endpoints.employee}/${id}`, data, config);
	},

	delete: async function (id: number): Promise<ApiResponse<null>> {
		return this.api.delete(`${this.api.endpoints.employee}/${id}`);
	},

	upload: async function (data: Partial<FormData>): Promise<ApiResponse<Employee>> {
		let config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};
		return this.api.post(`${this.api.endpoints.employeeImport}/import`, data, config);
	},

	get: async function (id: DbId): Promise<ApiResponse<Employee>> {
		return this.api.get(`${this.api.endpoints.employee}/${id}`);
	},

	uploadList: async function (
		params: EmployeeFilter & { limit?: number; offset?: number } = {}
	): Promise<ApiResponse<EmployeeList>> {
		const { limit, offset, ...filtro } = params;
		let config = {
			params: {
				...filtro,
				limit,
				offset,
			},
		};
		return this.api.get(`${this.api.endpoints.employeeImport}`, config);
	},

	uploadDelete: async function (id: number): Promise<ApiResponse<null>> {
		return this.api.delete(`${this.api.endpoints.employeeImport}/${id}/delete`);
	},

	uploadGet: async function (id: DbId): Promise<ApiResponse<Employee>> {
		return this.api.get(`${this.api.endpoints.employeeImport}/${id}`);
	},

	uploadUpdate: async function (id: number, data: Partial<any>): Promise<ApiResponse<Employee>> {
		return this.api.patch(`${this.api.endpoints.employeeImport}/${id}/correct`, data);
	},

	uploadSend: async function (data: Partial<any>): Promise<ApiResponse<Employee>> {
		return this.api.post(`${this.api.endpoints.employeeImport}/send`, data);
	},
};
