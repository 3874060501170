/* eslint-disable no-case-declarations */
import React, { InputHTMLAttributes } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import { FieldErrors, Control, Controller } from 'react-hook-form';
import { useToggle } from '@/hooks';
import MaskedInput, { Mask } from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { Typeahead, TypeaheadComponentProps } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

type PasswordInputProps = {
	name: string;
	placeholder?: string;
	refCallback?: any;
	errors: FieldErrors;
	control?: Control<any>;
	register?: any;
	className?: string;
};

/* Password Input */
const PasswordInput = ({
	name,
	placeholder,
	refCallback,
	errors,
	register,
	className,
}: PasswordInputProps) => {
	const [showPassword, togglePassword] = useToggle();

	return (
		<InputGroup className="mb-0">
			<Form.Control
				type={showPassword ? 'text' : 'password'}
				placeholder={placeholder}
				name={name}
				id={name}
				as="input"
				ref={(r: HTMLInputElement) => {
					if (refCallback) refCallback(r);
				}}
				className={className}
				isInvalid={errors && errors[name] ? true : false}
				{...(register ? register(name) : {})}
				autoComplete={name}
			/>
			<div
				className={classNames('input-group-text', 'input-group-password', {
					'show-password': showPassword,
				})}
				data-password={showPassword ? 'true' : 'false'}
			>
				<span className="password-eye" onClick={togglePassword}></span>
			</div>
		</InputGroup>
	);
};

type FormInputProps = InputHTMLAttributes<HTMLInputElement> &
	Partial<TypeaheadComponentProps> & {
		label?: string;
		type?:
			| 'hidden'
			| 'password'
			| 'textarea'
			| 'text'
			| 'select'
			| 'typeahead'
			| 'number'
			| 'checkbox'
			| 'switch'
			| 'radio'
			| 'money'
			| 'decimal'
			| 'range'
			| 'email'
			| 'file'
			| 'date'
			| 'month'
			| 'time'
			| 'week'
			| 'color'
			| 'cnpj'
			| 'cpf'
			| 'percentage2cases'
			| 'phone_number'
			| 'cep';
		id?: string;
		name: string;
		placeholder?: string;
		register?: any;
		errors?: FieldErrors | any;
		control?: Control<any>;
		className?: string;
		labelClassName?: string;
		containerClass?: string;
		refCallback?: any;
		children?: React.ReactNode;
		rows?: string;
		prefix?: any;
		suffix?: any;
		newSelectionPrefix?: string;
		onChangeCustom?: (e: any) => void;
	};

const InputMask = (type: string): Mask => {
	switch (type) {
		case 'money':
			return createNumberMask({
				prefix: '',
				suffix: '',
				includeThousandsSeparator: true,
				thousandsSeparatorSymbol: '.',
				allowDecimal: true,
				decimalSymbol: ',',
				decimalLimit: 2,
				integerLimit: 9,
				requireDecimal: true,
				allowNegative: false,
				allowLeadingZeroes: false,
			});
		case 'percentage2cases':
			return [/\d/, /\d/, '%'];
		case 'cnpj':
			return [
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'/',
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				'-',
				/\d/,
				/\d/,
			];
		case 'cpf':
			return [
				/\d/,
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'-',
				/\d/,
				/\d/,
			];
		case 'phone_number':
			return [
				'(',
				/\d/,
				/\d/,
				')',
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				'-',
				/\d/,
				/\d/,
				/\d/,
				/\d/,
			];
		case 'cep':
			return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
		default:
			return [];
	}
};

const FormInput = ({
	label,
	type,
	id,
	name,
	placeholder,
	register,
	errors,
	control,
	className,
	labelClassName,
	containerClass,
	refCallback,
	children,
	rows,
	prefix,
	suffix,
	onChangeCustom = () => {},
	...otherProps
}: FormInputProps & { htmlSize?: number }) => {
	// handle input type
	const comp = type === 'textarea' ? 'textarea' : type === 'select' ? 'select' : 'input';

	let component;
	switch (type) {
		//handle masks
		case 'money':
		case 'percentage2cases':
		case 'cnpj':
		case 'cpf':
		case 'phone_number':
		case 'cep':
			let mask: Mask = InputMask(type);
			component = (
				<Form.Group className={containerClass}>
					{label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}

					<InputGroup
						className={classNames({
							'is-invalid': errors && errors[name],
						})}
					>
						{prefix && <InputGroup.Text>{prefix}</InputGroup.Text>}
						<Controller
							render={({ field, fieldState }) => {
								return (
									<>
										<MaskedInput
											mask={mask}
											className={classNames('form-control', className, {
												'is-invalid':
													Boolean(fieldState.error?.message) ||
													(errors && errors[name]),
											})}
											placeholder={placeholder ?? '0'}
											id={id ?? name}
											autoComplete={name}
											name={name}
											value={field.value}
											// {...(register ? register(name) : {})}
											{...otherProps}
											onChange={(e) => {
												field.onChange(e);
												onChangeCustom(e);
											}}
											onBlur={field.onBlur}
										>
											{children ? children : null}
										</MaskedInput>
										{fieldState.error?.message && (
											<Form.Control.Feedback type="invalid">
												{fieldState.error?.message}
											</Form.Control.Feedback>
										)}
									</>
								);
							}}
							control={control}
							{...(register ? register(name) : { name: name })}
						/>
						{suffix && <InputGroup.Text>{suffix}</InputGroup.Text>}
					</InputGroup>
					{errors && errors[name] ? (
						<Form.Control.Feedback type="invalid">
							{errors?.[name]?.['message'] as string}
							{errors[name] &&
								Array.isArray(errors[name]) &&
								errors[name].map((message: string) => message)}
						</Form.Control.Feedback>
					) : null}
				</Form.Group>
			);
			break;
		case 'decimal':
			const numberMask = createNumberMask({
				prefix: '',
				suffix: '',
				includeThousandsSeparator: true,
				thousandsSeparatorSymbol: '.',
				allowDecimal: true,
				decimalSymbol: ',',
				decimalLimit: 2,
				integerLimit: 9,
				requireDecimal: true,
				allowNegative: false,
				allowLeadingZeroes: false,
			});
			component = (
				<Form.Group className={containerClass}>
					{label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}

					<Controller
						render={({ field }) => {
							return (
								<MaskedInput
									mask={numberMask}
									className={classNames('form-control', className, {
										'is-invalid': errors && errors[name],
									})}
									placeholder={placeholder ?? '0'}
									id={id ?? name}
									autoComplete={name}
									name={name}
									value={field.value}
									// {...(register ? register(name) : {})}
									{...otherProps}
									onChange={field.onChange}
									onBlur={field.onBlur}
								>
									{children ? children : null}
								</MaskedInput>
							);
						}}
						control={control}
						{...(register ? register(name) : { name: name })}
					/>

					{errors && errors[name] ? (
						<Form.Control.Feedback type="invalid">
							{errors?.[name]?.['message'] as string}
						</Form.Control.Feedback>
					) : null}
				</Form.Group>
			);
			break;
		case 'hidden':
			component = (
				<input
					type={type}
					{...(register ? register(name) : { name: name })}
					{...otherProps}
				/>
			);
			break;
		case 'password':
			component = (
				<>
					<Form.Group className={containerClass}>
						{label ? (
							<>
								{' '}
								<Form.Label className={labelClassName}>{label}</Form.Label>{' '}
								{children}{' '}
							</>
						) : null}
						<PasswordInput
							name={name}
							placeholder={placeholder}
							refCallback={refCallback}
							errors={errors!}
							register={register}
							className={className}
						/>

						{errors && errors[name] ? (
							<Form.Control.Feedback type="invalid" className="d-block">
								{errors?.[name]?.['message'] as string}
							</Form.Control.Feedback>
						) : null}
					</Form.Group>
				</>
			);
			break;
		case 'select':
			component = (
				<Form.Group className={classNames(containerClass, 'position-relative')}>
					{label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}

					<Form.Select
						// type={type}
						// label={label}
						id={id ?? name}
						// ref={(r: HTMLInputElement) => {
						//     if (refCallback) refCallback(r);
						// }}
						// comp={comp}
						className={className}
						isInvalid={errors && errors[name] ? true : false}
						{...(register ? register(name) : {})}
						{...otherProps}
					>
						{children}
					</Form.Select>

					{errors && errors[name] ? (
						<Form.Control.Feedback type="invalid">
							{errors?.[name]?.['message'] as string}
						</Form.Control.Feedback>
					) : null}
				</Form.Group>
			);
			break;
		case 'typeahead':
			component = (
				<Controller
					name={name}
					control={control}
					render={({ field, fieldState }) => (
						<Form.Group className={containerClass ?? ''}>
							{label && <Form.Label>{label}</Form.Label>}
							<Typeahead
								id={id ?? name}
								className={classNames(
									className,
									fieldState.invalid ? 'is-invalid' : ''
								)}
								isInvalid={
									fieldState.error?.message || (errors && errors[name])
										? true
										: false
								}
								placeholder={placeholder}
								{...(register ? register(name) : {})}
								onChange={(e: any) => {
									field.onChange(e);
									onChangeCustom(e);
								}}
								onBlur={field.onBlur}
								defaultSelected={otherProps.options?.filter(
									(i: any) =>
										control?._formValues[name]
											?.map((j: any) => j.id)
											?.includes(i?.id) ?? false
								)}
								{...otherProps}
							>
								{children}
							</Typeahead>
							{fieldState.error?.message && (
								<Form.Control.Feedback type="invalid">
									{fieldState.error?.message}
								</Form.Control.Feedback>
							)}
							{errors && errors[name] && (
								<Form.Control.Feedback type="invalid">
									{errors?.[name]?.['message'] as string}
								</Form.Control.Feedback>
							)}
						</Form.Group>
					)}
				/>
			);
			break;
		case 'switch':
		case 'checkbox':
		case 'radio':
			component = (
				<Form.Group className={containerClass}>
					<Form.Check
						type={type}
						label={label}
						id={id ?? name}
						className={className}
						isInvalid={errors && errors[name] ? true : false}
						{...(register ? register(name) : {})}
						{...otherProps}
					/>

					{errors && errors[name] ? (
						<Form.Control.Feedback type="invalid">
							{errors?.[name]?.['message'] as string}
						</Form.Control.Feedback>
					) : null}
				</Form.Group>
			);
			break;
		case 'range':
			component = (
				<Form.Group className={containerClass}>
					{label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}

					<Form.Range
						id={id ?? name}
						className={className}
						isInvalid={errors && errors[name] ? true : false}
						{...(register ? register(name) : {})}
						{...otherProps}
					/>

					{errors && errors[name] ? (
						<Form.Control.Feedback type="invalid">
							{errors?.[name]?.['message'] as string}
						</Form.Control.Feedback>
					) : null}
				</Form.Group>
			);
			break;
		default:
			component = (
				<Form.Group className={containerClass}>
					{label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}

					<Form.Control
						type={type}
						placeholder={placeholder}
						id={id ?? name}
						as={comp}
						className={className}
						isInvalid={errors && errors[name] ? true : false}
						{...(register ? register(name) : {})}
						{...otherProps}
						autoComplete={name}
					>
						{children ? children : null}
					</Form.Control>

					{errors && errors[name] ? (
						<Form.Control.Feedback type="invalid">
							{errors?.[name]?.['message'] as string}
						</Form.Control.Feedback>
					) : null}
				</Form.Group>
			);
	}
	return <>{component}</>;
};

export default FormInput;
