export const ThemeSettings = {
	layout: {
		type: { vertical: 'vertical', horizontal: 'horizontal' },
		mode: { fluid: 'fluid', boxed: 'boxed', detached: 'detached' },
		menuPosition: { scrollable: 'scrollable', fixed: 'fixed' },
	},
	theme: { light: 'light', dark: 'dark' },
	topbar: {
		theme: { light: 'light', dark: 'dark', brand: 'brand' },
		logo: { hidden: 'fullscreen', show: '' },
	},
	sidebar: {
		theme: { light: 'light', dark: 'dark', brand: 'brand' },
		size: {
			default: 'default',
			compact: 'compact',
			condensed: 'condensed',
			showOnHover: 'sm-hover',
			full: 'full',
			fullscreen: 'fullscreen',
		},
		user: { show: true, hidden: false },
	},
	rightSidebar: { show: true, hidden: false },
	templateMenus: { show: true, hidden: false },
};

export type Theme = {
	layout: {
		type: string;
		mode: string;
		menuPosition: string;
	};
	theme: string;
	topbar: {
		theme: string;
		logo: string;
	};
	sidebar: {
		theme: string;
		size: string;
		user: boolean;
	};
	rightSidebar: boolean;
	templateMenus: boolean;
};

export const defaultTheme: Theme = {
	layout: {
		type: ThemeSettings.layout.type.vertical,
		mode: ThemeSettings.layout.mode.fluid,
		menuPosition: ThemeSettings.layout.menuPosition.fixed,
	},
	theme: ThemeSettings.theme.light,
	topbar: {
		theme: ThemeSettings.topbar.theme.light,
		logo: ThemeSettings.topbar.logo.show,
	},
	sidebar: {
		theme: ThemeSettings.sidebar.theme.dark,
		size: ThemeSettings.sidebar.size.default,
		user: ThemeSettings.sidebar.user.hidden,
	},
	rightSidebar: ThemeSettings.rightSidebar.hidden,
	templateMenus: ThemeSettings.templateMenus.hidden,
};
