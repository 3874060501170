import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.min.css';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { Form } from 'react-bootstrap';

type DatepickerInputProps = {
	onClick?: () => void;
	value?: string;
	inputClass: string;
	children?: React.ReactNode;
	error?: string;
};

/* Datepicker with Input */
// eslint-disable-next-line react/display-name
const DatepickerInput = forwardRef<HTMLInputElement, DatepickerInputProps>((props, ref) => {
	const onDateValueChange = () => {
	};
	return (
		<input
			type="text"
			className={props.error ? 'form-control date is-invalid' : 'form-control date'}
			onClick={props.onClick}
			value={props?.value}
			onChange={onDateValueChange}
			placeholder="__/__/_____"
			ref={ref}
		/>
	);
});

/* Datepicker with Addon Input */
// eslint-disable-next-line react/display-name
const DatepickerInputWithAddon = forwardRef<HTMLInputElement, DatepickerInputProps>(
	(props, ref) => (
		<>
			<div className="input-group" ref={ref}>
				<input
					type="text"
					className={props.error ? 'form-control date is-invalid' : 'form-control date'}
					onClick={props.onClick}
					value={props?.value}
					placeholder="__/__/_____"
					readOnly
				/>
				<div className="input-group-append">
					<span className="input-group-text bg-link border-link">
						<i className="mdi mdi-calendar-range font-13"></i>
					</span>
				</div>
			</div>
		</>
	)
);

type HyperDatepickerProps = {
	name: string;
	value?: Date;
	onChange: (date: Date) => void;
	hideAddon?: boolean;
	inputClass?: string;
	dateFormat?: string;
	minDate?: Date;
	maxDate?: Date;
	className?: string;
	containerClass?: string;
	label?: string;
	showTimeSelect?: boolean;
	tI?: number;
	timeFormat?: string;
	timeCaption?: string;
	showTimeSelectOnly?: boolean;
	monthsShown?: number;
	inline?: boolean;
	control?: Control<any>;
};

const CustomDatePicker = (props: HyperDatepickerProps) => {
	const { control } = useFormContext();
	return (
		<Controller
			name={props?.name}
			control={control}
			render={({ field, fieldState }) => {
				return (
					<Form.Group className={props.containerClass ?? ''}>
						{props.label && <Form.Label>{props.label}</Form.Label>}
						<DatePicker
							customInput={
								(props.hideAddon || false) === true ? (
									<DatepickerInput
										inputClass={props.inputClass ?? ''}
										value={props?.value ? props?.value.toDateString() : ''}
										error={fieldState.error?.message}
									/>
								) : (
									<DatepickerInputWithAddon
										inputClass={props.inputClass ?? ''}
										value={props?.value ? props?.value.toDateString() : ''}
										error={fieldState.error?.message}
									/>
								)
							}
							timeIntervals={props.tI}
							selected={props?.value}
							onChange={(date: Date) => {
								props.onChange(date);
								field.onChange(date);
							}}
							showTimeSelect={props.showTimeSelect}
							timeFormat={props.timeFormat || 'hh:mm a'}
							timeCaption={props.timeCaption}
							dateFormat={props.dateFormat || 'dd/MM/yyyy'}
							minDate={props.minDate}
							maxDate={props.maxDate}
							monthsShown={props.monthsShown}
							showTimeSelectOnly={props.showTimeSelectOnly}
							inline={props.inline}
							autoComplete="off"
						/>
						{fieldState.error?.message && (
							<>
								<div className="is-invalid" />
								<Form.Control.Feedback type="invalid">
									{fieldState.error?.message}
								</Form.Control.Feedback>
							</>
						)}
					</Form.Group>
				);
			}}
		/>
	);
};

export default CustomDatePicker;
