/* eslint-disable no-prototype-builtins */
import React, { useRef, useEffect, forwardRef, useState } from 'react';
import {
	useTable,
	useSortBy,
	usePagination,
	useRowSelect,
	useGlobalFilter,
	useAsyncDebounce,
	useExpanded,
	Column,
	Row,
	FilterValue,
	HeaderProps,
} from 'react-table';
import classNames from 'classnames';
import { Pagination, PageSize } from './Pagination';
import { Col, Row as GridRow } from 'react-bootstrap';

export type CellFormatter<T extends Object = {}> = {
	row: Row<T> | any;
};

type GlobalFilterProps = {
	preGlobalFilteredRows: any;
	globalFilter: any;
	setGlobalFilter: (filterValue: FilterValue) => void;
	searchBoxClass?: string;
};

const GlobalFilter = ({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
	searchBoxClass,
}: GlobalFilterProps) => {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = useState<any>(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<div className={classNames(searchBoxClass)}>
			<span className="d-flex align-items-center">
				Pesquisar :
				<input
					value={value || ''}
					onChange={(e) => {
						setValue(e.target.value);
						onChange(e.target.value);
					}}
					placeholder={`${count} Registros...`}
					className="form-control w-auto ms-1"
				/>
			</span>
		</div>
	);
};

type IndeterminateCheckboxProps = {
	indeterminate?: any;
	children?: React.ReactNode;
};

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = useRef();
		const resolvedRef: any = ref || defaultRef;

		useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return (
			<div className="form-check">
				<input type="checkbox" className="form-check-input" ref={resolvedRef} {...rest} />
				<label htmlFor="form-check-input" className="form-check-label"></label>
			</div>
		);
	}
);

type TableProps<TableValues> = {
	isSearchable?: boolean;
	isSortable?: boolean;
	pagination?: boolean;
	isSelectable?: boolean;
	isExpandable?: boolean;
	sizePerPageList?: PageSize[];
	columns: ReadonlyArray<Column>;
	data: TableValues[];
	pageSize?: number;
	searchBoxClass?: string;
	tableClass?: string;
	theadClass?: string;
	filterComponent?: React.ReactNode;
	loading?: boolean;
};

const Table = <TableValues extends object = {}>(props: TableProps<TableValues>) => {
	const isSearchable = props['isSearchable'] || false;
	const isSortable = props['isSortable'] || false;
	const pagination = props['pagination'] || false;
	const isSelectable = props['isSelectable'] || false;
	const isExpandable = props['isExpandable'] || false;
	const sizePerPageList = props['sizePerPageList'] || [];

	let otherProps: any = {};

	if (isSearchable) {
		otherProps['useGlobalFilter'] = useGlobalFilter;
	}
	if (isSortable) {
		otherProps['useSortBy'] = useSortBy;
	}
	if (isExpandable) {
		otherProps['useExpanded'] = useExpanded;
	}
	if (pagination) {
		otherProps['usePagination'] = usePagination;
	}
	if (isSelectable) {
		otherProps['useRowSelect'] = useRowSelect;
	}

	const dataTable = useTable(
		{
			columns: props.columns,
			data: props['data'],
			initialState: { pageSize: props['pageSize'] || 10 },
		},

		otherProps.hasOwnProperty('useGlobalFilter') && otherProps['useGlobalFilter'],
		otherProps.hasOwnProperty('useSortBy') && otherProps['useSortBy'],
		otherProps.hasOwnProperty('useExpanded') && otherProps['useExpanded'],
		otherProps.hasOwnProperty('usePagination') && otherProps['usePagination'],
		otherProps.hasOwnProperty('useRowSelect') && otherProps['useRowSelect'],

		(hooks) => {
			isSelectable &&
				hooks.visibleColumns.push((columns) => [
					// Let's make a column for selection
					{
						id: 'selection',
						// The header can use the table's getToggleAllRowsSelectedProps method
						// to render a checkbox
						Header: ({ getToggleAllPageRowsSelectedProps }: HeaderProps<{}>) => (
							<div>
								<IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
							</div>
						),
						// The cell can use the individual row's getToggleRowSelectedProps method
						// to the render a checkbox
						Cell: ({ row }: any) => (
							<div>
								<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
							</div>
						),
					},
					...columns,
				]);

			isExpandable &&
				hooks.visibleColumns.push((columns) => [
					// Let's make a column for selection
					{
						// Build our expander column
						id: 'expander', // Make sure it has an ID
						Header: ({
							getToggleAllRowsExpandedProps,
							isAllRowsExpanded,
						}: HeaderProps<{}>) => (
							<span {...getToggleAllRowsExpandedProps()}>
								{isAllRowsExpanded ? '-' : '+'}
							</span>
						),
						Cell: ({ row }: any) =>
							// Use the row.canExpand and row.getToggleRowExpandedProps prop getter
							// to build the toggle for expanding a row
							row.canExpand ? (
								<span
									{...row.getToggleRowExpandedProps({
										style: {
											// We can even use the row.depth property
											// and paddingLeft to indicate the depth
											// of the row
											paddingLeft: `${row.depth * 2}rem`,
										},
									})}
								>
									{row.isExpanded ? '-' : '+'}
								</span>
							) : null,
					},
					...columns,
				]);
		}
	);

	const rows = pagination ? dataTable.page : dataTable.rows;

	return (
		<>
			<GridRow>
				<Col xs={'auto'}>
					{isSearchable && (
						<GlobalFilter
							preGlobalFilteredRows={dataTable.preGlobalFilteredRows}
							globalFilter={dataTable.state.globalFilter}
							setGlobalFilter={dataTable.setGlobalFilter}
							searchBoxClass={props['searchBoxClass']}
						/>
					)}
				</Col>
				<Col>{props.filterComponent}</Col>
			</GridRow>

			<div className="table-responsive">
				<table
					{...dataTable.getTableProps()}
					className={classNames('table table-centered react-table', props['tableClass'])}
				>
					<thead className={props['theadClass']}>
						{dataTable.headerGroups.map((headerGroup, index) => (
							<tr {...headerGroup.getHeaderGroupProps()} key={index}>
								{headerGroup.headers.map((column: any, index) => (
									<th
										{...column.getHeaderProps(
											column.defaultCanSort && column.getSortByToggleProps()
										)}
										className={classNames({
											sorting_desc: column.isSortedDesc === true,
											sorting_asc: column.isSortedDesc === false,
											sortable: column.defaultCanSort === true,
										})}
										key={index}
									>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...dataTable.getTableBodyProps()}>
						{props.loading !== undefined && props.loading ? (
							<tr>
								<td className="text-center" colSpan={100}>
									<div className="bouncing-loader">
										<div></div>
										<div></div>
										<div></div>
									</div>
								</td>
							</tr>
						) : (
							<>
								{rows.length === 0 ? (
									<tr>
										<td className="text-center" colSpan={100}>
											Não foram encontrados dados
										</td>
									</tr>
								) : (
									<></>
								)}
								{(rows || []).map((row, index) => {
									dataTable.prepareRow(row);
									return (
										<tr {...row.getRowProps()} key={index}>
											{row.cells.map((cell, index) => {
												return (
													<td {...cell.getCellProps()} key={index}>
														{cell.render('Cell')}
													</td>
												);
											})}
										</tr>
									);
								})}
							</>
						)}
					</tbody>
				</table>
			</div>

			{pagination && <Pagination tableProps={dataTable} sizePerPageList={sizePerPageList} />}
		</>
	);
};

export { Table };
