import { AuthRepository } from '@/data/api';
import { useAuthContext, useNotificationContext } from '@/common/context';
import { useState } from 'react';
import { t } from 'i18next';

export default function useLogout() {
	const { removeSession } = useAuthContext();
	const { showNotification } = useNotificationContext();
	const [logoutSuccess, setLogoutSuccess] = useState(false);

	const logout = async () => {
		const res = await AuthRepository.logout();
		try {
			switch (res.status) {
				case 200:
					setLogoutSuccess(true);
					localStorage.removeItem('access_token')
					removeSession();
					break;
				case 403:
					showNotification({ message: t('login_forbidden'), type: 'error' });
					throw new Error(res.data?.detail || t('server_error'));
				default:
					showNotification({
						message: 'Erro Inesperado ao realizar logout',
						type: 'error',
					});
					throw new Error(res.data?.detail || t('server_error'));
			}
		} catch (error: any) {
			console.log(error);
		}
	};

	return { logoutSuccess, logout };
}
