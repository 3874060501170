import { useNotificationContext } from '@/common/context';
import { EmployeeRepository } from '@/data/api/employee';
import { useState } from 'react';
import { t } from 'i18next';
import * as yup from 'yup';
import validator from '@/common/helpers/cpf-cnpj-validator/validator';
import { Errors } from '@/types/types';
import useUF from '../../../../common/hooks/useAddress';
import { ConfirmData } from '@/types/ConfirmData';
import useLogout from '@/pages/account/Logout/useLogout';
import { useParams } from 'react-router-dom';
import { ConfirmDataRepository } from '@/data/api/confirmData';

type ConnectionType = {
	id: number;
	value: string;
	label: string;
};

export default function useEmployee(handleSuccess?: () => void) {
	const [waitingApi, setWaitingApi] = useState<boolean>(false);
	const [errors, setErrors] = useState<Errors>();
	const { showNotification } = useNotificationContext();
	const [defaultValues, setDefaultValues] = useState<any>();
	const [employeeId, setEmployeeId] = useState<number | null>(null);
	const useUf = useUF(true);
	const [selectedConnection, setSelectedConnection] = useState<string>();
	const [employeeData, setEmployeeData] = useState<ConfirmData>();
	const [startDate, setStartDate] = useState<Date>();
	const { logout } = useLogout();
	const { token } = useParams<{ token: string }>();
	const [updateSuccess, setUpdateSuccess] = useState(false);

	const ConnectionType: Array<ConnectionType> = [
		{ id: 1, value: 'monofasico', label: 'Monofásico' },
		{ id: 2, value: 'bifasico', label: 'Bifásico' },
		{ id: 3, value: 'trifasico', label: 'Trifásico' },
	];

	const onChangeConnectionType = (selected: string) => {
		setSelectedConnection(selected);
	};

	const handleValidSubmit = (form: Record<string, any>, token: string, update: boolean) => {
		if (update) {
			updateEmployeeBill(mapToEdit(form));
		} else {
			createEmployee(mapToCreate(form, token));
		}
	};

	function appendIfNotNull(formData: FormData, key: string, value: string | null | undefined) {
		if (value !== null && value !== undefined) {
			formData.append(key, value);
		}
	}

	function mapErrors(response: any): void {
		let errors: any = {};
		if (response?.detail) {
			errors['detail'] = response.detail;
		}
		if (response?.message) {
			errors['message'] = response.message;
		}
		if (response?.file_csv) {
			errors['file_csv'] = response.file_csv;
		}
		if (response?.error) {
			errors['error'] = response.error;
		}
		if (response?.name) {
			errors['name'] = response.name;
		}
		if (response?.cpf) {
			errors['cpf'] = response.cpf;
		}
		if (response?.identidade) {
			errors['identidade'] = response.identidade;
		}
		if (response?.email) {
			errors['email'] = response.email;
		}
		if (response?.phone_number) {
			errors['phone_number'] = response.phone_number;
		}
		if (response?.file_document) {
			errors['file_document'] = response.file_document;
		}

		if (response?.employee_bill) {
			for (let key in response.employee_bill) {
				errors[key] = response.employee_bill[key][0];
			}
		}

		if (response?.address) {
			for (let key in response.address) {
				errors[key] = response.address[key][0];
			}
		}

		setErrors(errors);
	}

	const schema = yup.object().shape({
		employee_bill: yup.object().shape({
			name: yup
				.string()
				.test('full_name', 'Por favor, insira nome e sobrenome', (value) => {
					if (!value) return false;
					const parts = value.split(' ');
					if (parts.length < 2) return false;
					return /^[a-zA-ZÀ-ÿ]+$/.test(parts[1]);
				})
				.required('Por favor insira Nome do Contrato'),
			cpf: yup
				.string()
				.required('Por favor insira CPF')
				.test('cpfValido', 'CPF inválido', (value) => {
					return validator.isCPF(value);
				}),
			identidade: yup.string().required('Por favor insira sua identidade'),
			phone_number: yup.string().required('Por favor insira Número de Telefone'),
			email: yup
				.string()
				.email('E-mail inválido')
				.matches(/^[^@]+@[^@]+(\.[a-zA-Z]+)+$/, 'E-mail inválido')
				.required('Por favor insira um E-mail'),
			birthdate: yup.string().required('Por favor insira a data de nascimento do titular'),
			// number: yup.string().required('Por favor insira o numero da sua unidade de consumo'),
			// quantity: yup.string().required('Por favor insira uma média de consumo'),
			// connection_type: yup.string().required('Por favor selecione o tipo de conexão'),
			address: yup.object().shape({
				cep: yup.string().required('Por favor digite o cep'),
				state: yup.string().required('Por favor selecione o estado'),
				city: yup.string().required('Por favor selecione a cidade'),
				neighborhood: yup.string().required('Por favor digite o bairro'),
				street: yup.string().required('Por favor digite a rua'),
				number: yup.string().required('Por favor digite o número'),
				complement: yup.string(),
			}),
		}),
	});

	const schemaForCreate = yup.object().shape({
		employee_bill: yup.object().shape({
			name: yup
				.string()
				.test('full_name', 'Por favor, insira nome e sobrenome', (value) => {
					if (!value) return false;
					const parts = value.split(' ');
					if (parts.length < 2) return false;
					return /^[a-zA-ZÀ-ÿ]+$/.test(parts[1]);
				})
				.required('Por favor insira Nome do Contrato'),
			cpf: yup
				.string()
				.required('Por favor insira CPF')
				.test('cpfValido', 'CPF inválido', (value) => {
					return validator.isCPF(value);
				}),
			phone_number: yup.string().required('Por favor insira Número de Telefone'),
			identidade: yup.string().required('Por favor insira sua identidade'),
			email: yup
				.string()
				.email('E-mail inválido')
				.matches(/^[^@]+@[^@]+(\.[a-zA-Z]+)+$/, 'E-mail inválido')
				.required('Por favor insira um E-mail'),
			birthdate: yup.string().required('Por favor insira a data de nascimento do titular'),
			// number: yup.string().required('Por favor insira o numero da sua unidade de consumo'),
			// quantity: yup.string().required('Por favor insira uma média de consumo'),
			// connection_type: yup.string().required('Por favor selecione o tipo de conexão'),
			file_document: yup
				.mixed()
				.test('file', 'Por favor, selecione um arquivo', (value) => {
					return value && value instanceof File;
				})
				.test('fileSize', 'O arquivo é muito grande', (value) => {
					const file = value as File;
					return file && file.size <= 20 * 1024 * 1024;
				})
				.test(
					'fileType',
					'O arquivo deve ser *.doc, *.docx ou *.pdf ou Imagens',
					(value) => {
						if (value) {
							const file = value as File;
							// Tipos de arquivo aceitos
							const acceptedDocumentExtensions = ['.docx', '.doc', '.pdf'];
							const acceptedImageTypes = [
								'image/jpeg',
								'image/png',
								'image/gif',
								'image/webp',
								'image/bmp',
								'image/svg+xml',
								'image/tiff',
								'image/x-icon',
							];

							const fileExtension = file.name.split('.').pop()?.toLowerCase();
							const fileType = file.type;

							// Verificando se o arquivo é um documento aceito
							const isDocument =
								fileType.startsWith('application/') &&
								acceptedDocumentExtensions.includes(`.${fileExtension}`);

							// Verificando se o arquivo é uma imagem aceita
							const isImage = acceptedImageTypes.includes(fileType);

							return isDocument || isImage;
						}
						return false;
					}
				)
				.required('Você precisa enviar uma conta'),
			address: yup.object().shape({
				cep: yup.string().required('Por favor digite o cep'),
				state: yup.string().required('Por favor selecione o estado'),
				city: yup.string().required('Por favor selecione a cidade'),
				neighborhood: yup.string().required('Por favor digite o bairro'),
				street: yup.string().required('Por favor digite a rua'),
				number: yup.string().required('Por favor digite o número'),
				complement: yup.string(),
			}),
		}),
	});

	function formartDate(date: string): string {
		const originalDate = new Date(date);
		const year = originalDate.getFullYear();
		const month = String(originalDate.getMonth() + 1).padStart(2, '0');
		const day = String(originalDate.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	}

	function mapToDefaultValues(data: any): void {
		let values = {
			employee_bill: {
				name: data.name || '',
				cpf: data.cpf || '',
				identidade: data.identidade,
				phone_number: data.phone_number || '',
				email: data.email || '',
				// number: data.number != null ? data.number.toString() : '',
				// quantity: data.quantity != null ? data.quantity.toString() : '',
				// connection_type: data.connection_type || '',
				birthdate: data.birthdate || '',
				address: {
					cep: data?.address?.cep,
					city: data?.address?.city,
					complement: data?.address?.complement,
					id: data?.address?.id,
					is_active: data?.address?.is_active,
					neighborhood: data?.address?.neighborhood,
					number: data?.address?.number,
					state: data?.address?.state,
					street: data?.address?.street,
				},
			},
		};
		if (data?.birthdate) setStartDate(new Date(data.birthdate));
		setDefaultValues(values);
	}

	function mapToCreate(obj: any, token: string): FormData {
		const formData = new FormData();
		appendIfNotNull(formData, 'confirmation_token', token);
		//Dados Employee
		appendIfNotNull(formData, 'name', employeeData?.name);
		appendIfNotNull(formData, 'cpf', employeeData?.cpf?.replace(/\D/g, ''));
		appendIfNotNull(formData, 'email', employeeData?.email);
		appendIfNotNull(formData, 'birthdate', employeeData?.birthdate);
		appendIfNotNull(formData, 'phone_number', employeeData?.telefone?.replace(/\D/g, ''));
		appendIfNotNull(formData, 'file_document', obj?.employee_bill.file_document);
		appendIfNotNull(formData, 'terms_of_use', 'true');
		//Dados Employee bill
		appendIfNotNull(formData, 'employee_bill.name', obj.employee_bill.name);
		appendIfNotNull(
			formData,
			'employee_bill.birthdate',
			formartDate(obj.employee_bill.birthdate)
		);
		appendIfNotNull(formData, 'employee_bill.cpf', obj?.employee_bill?.cpf.replace(/\D/g, ''));
		appendIfNotNull(formData, 'employee_bill.identidade', obj?.employee_bill?.identidade);
		appendIfNotNull(formData, 'employee_bill.email', obj.employee_bill.email);
		appendIfNotNull(
			formData,
			'employee_bill.phone_number',
			obj?.employee_bill?.phone_number.replace(/\D/g, '')
		);

		// Endereço da conta
		appendIfNotNull(
			formData,
			'employee_bill.address.cep',
			obj?.employee_bill?.address?.cep?.replace(/\D/g, '')
		);
		appendIfNotNull(
			formData,
			'employee_bill.address.state',
			obj?.employee_bill?.address?.state
		);
		appendIfNotNull(formData, 'employee_bill.address.city', obj?.employee_bill?.address?.city);
		appendIfNotNull(
			formData,
			'employee_bill.address.neighborhood',
			obj?.employee_bill?.address?.neighborhood
		);
		appendIfNotNull(
			formData,
			'employee_bill.address.street',
			obj?.employee_bill?.address?.street
		);
		appendIfNotNull(
			formData,
			'employee_bill.address.number',
			obj?.employee_bill?.address?.number
		);
		appendIfNotNull(
			formData,
			'employee_bill.address.complement',
			obj?.employee_bill?.address?.complement
		);
		// Dados de energia
		// appendIfNotNull(formData, 'employee_bill.number', obj.employee_bill.number);
		// appendIfNotNull(formData, 'employee_bill.quantity', obj.employee_bill.quantity);
		// appendIfNotNull(
		// 	formData,
		// 	'employee_bill.connection_type',
		// 	obj.employee_bill.connection_type
		// );
		appendIfNotNull(formData, 'employee_bill.file_document', obj?.employee_bill.file_document);

		return formData;
	}

	function mapToEdit(obj: any): FormData {
		const formData = new FormData();
		//Dados Employee bill
		appendIfNotNull(formData, 'email', obj?.employee_bill?.email);
		appendIfNotNull(formData, 'phone_number', obj?.employee_bill?.telefone?.replace(/\D/g, ''));
		appendIfNotNull(formData, 'name', obj?.employee_bill.name);
		appendIfNotNull(formData, 'birthdate', formartDate(obj.employee_bill.birthdate));
		appendIfNotNull(formData, 'cpf', obj?.employee_bill?.cpf?.replace(/\D/g, ''));
		appendIfNotNull(formData, 'identidade', obj?.employee_bill?.identidade);

		// Endereço da conta
		appendIfNotNull(
			formData,
			'address.cep',
			obj?.employee_bill?.address?.cep?.replace(/\D/g, '')
		);
		appendIfNotNull(formData, 'address.state', obj?.employee_bill?.address?.state);
		appendIfNotNull(formData, 'address.city', obj?.employee_bill?.address?.city);
		appendIfNotNull(
			formData,
			'address.neighborhood',
			obj?.employee_bill?.address?.neighborhood
		);
		appendIfNotNull(formData, 'address.street', obj?.employee_bill?.address?.street);
		appendIfNotNull(formData, 'address.number', obj?.employee_bill?.address?.number);
		appendIfNotNull(formData, 'address.complement', obj?.employee_bill?.address?.complement);

		// Dados de energia
		// appendIfNotNull(formData, 'number', obj.employee_bill.number);
		// appendIfNotNull(formData, 'quantity', obj.employee_bill.quantity);
		// appendIfNotNull(formData, 'connection_type', obj.employee_bill.connection_type);
		appendIfNotNull(formData, 'file_document', obj?.employee_bill.file_document);

		return formData;
	}

	const updateEmployeeBill = async (data: any) => {
		if (token) {
			setWaitingApi(true);
			const res = await ConfirmDataRepository.update(token, data);
			setWaitingApi(false);
			try {
				switch (res.status) {
					case 200:
					case 201:
						setUpdateSuccess(true);
						break;
					case 400:
						mapErrors(res.data);
						showNotification({ message: 'Erro no envio', type: 'error' });
						throw new Error(res.data?.detail || t('server_error'));
					case 401:
						mapErrors(res.data);
						logout();
						showNotification({ message: t('token_expired'), type: 'error' });
						break;
					case 403:
						mapErrors(res.data);
						showNotification({ message: t('login_forbidden'), type: 'error' });
						throw new Error(res.data?.detail || t('server_error'));
					default:
						mapErrors({ message: 'Erro Inesperado' });
						showNotification({
							message: 'Erro Inesperado',
							type: 'error',
						});
						throw new Error(res.data?.detail || t('server_error'));
				}
			} catch (error: any) {
				console.log(error);
			}
		}
	};

	const createEmployee = async (data: any) => {
		setWaitingApi(true);
		const res = await EmployeeRepository.create(data);
		setWaitingApi(false);
		try {
			switch (res.status) {
				case 201:
					if (handleSuccess !== undefined) {
						handleSuccess();
					}
					break;
				case 400:
					mapErrors(res.data);
					showNotification({ message: 'Erro no envio', type: 'error' });
					throw new Error(res.data?.detail || t('server_error'));
				case 401:
					mapErrors(res.data);
					logout();
					showNotification({ message: t('token_expired'), type: 'error' });
					break;
				case 403:
					mapErrors(res.data);
					showNotification({ message: t('login_forbidden'), type: 'error' });
					throw new Error(res.data?.detail || t('server_error'));
				default:
					mapErrors({ message: 'Erro Inesperado' });
					showNotification({
						message: 'Erro Inesperado',
						type: 'error',
					});
					throw new Error(res.data?.detail || t('server_error'));
			}
		} catch (error: any) {
			console.log(error);
		}
	};

	return {
		useUf,
		schema,
		handleValidSubmit,
		errors,
		setErrors,
		waitingApi,
		defaultValues,
		setWaitingApi,
		setEmployeeId,
		setDefaultValues,
		ConnectionType,
		selectedConnection,
		onChangeConnectionType,
		setEmployeeData,
		startDate,
		setStartDate,
		employeeId,
		mapToDefaultValues,
		schemaForCreate,
		updateSuccess,
	};
}
