import { Route, Routes as ReactRoutes } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import Confirmacao from '@/pages/formulario/confirmacao';

export default function AppRoutes() {
	return (
		<ReactRoutes>
			<Route path="confirmacao/:token" element={<Confirmacao />} />
			<Route path="atualizacao-dados/:token" element={<Confirmacao update={true} />} />
			<Route path="*" element={<ProtectedRoutes />} />
		</ReactRoutes>
	);
}
