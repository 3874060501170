import { HttpClient } from '@/data';

const config = {
	headers: {
		Authorization: null,
	},
};

export const AuthRepository = {
	get api() {
		return HttpClient();
	},
	login: async function (values: any) {
		return this.api.post(this.api.endpoints.login, values, config);
	},
	logout: async function () {
		return this.api.get(this.api.endpoints.logout, {});
	},
	register: async function (values: any) {
		return this.api.post(this.api.endpoints.register, values, config);
	},
	forgetPassword: async function (values: any) {
		return this.api.post(this.api.endpoints.forgotPassword, values, config);
	},
};
