import { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import { deleteAuthUser, getAuthUser, saveAuthUser } from '@/caches/user';
import { Session } from '@/types';

type TAuthContext = {
	user: Session | undefined;
	isAuthenticated: boolean;
	saveSession: (user: Session) => void;
	removeSession: () => void;
};

const AuthContext = createContext<TAuthContext | undefined>(undefined);

export function useAuthContext(): TAuthContext {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error('useAuthContext must be used within an AuthProvider');
	}
	return context;
}

export function AuthProvider({ children }: { children: ReactNode }) {
	const [user, setUser] = useState<Session | undefined>(
		getAuthUser()
		// localStorage.getItem(authSessionKey)
		// 	? JSON.parse(localStorage.getItem(authSessionKey) || '{}')
		// 	: undefined
	);

	const saveSession = useCallback(
		(user: Session) => {
			saveAuthUser(user);
			setUser(user);
		},
		[setUser]
	);

	const removeSession = useCallback(() => {
		deleteAuthUser();
		setUser(undefined);
	}, [setUser]);

	return (
		<AuthContext.Provider
			value={{
				user,
				isAuthenticated: Boolean(user),
				saveSession,
				removeSession,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}
