import { Session } from '@/types';

const authSessionKey = '_AUTH';

export const getAuthUser = (): Session | undefined => {
	const user = sessionStorage.getItem(authSessionKey);
	return user ? (typeof user == 'object' ? user : JSON.parse(user)) : undefined;
};
export const saveAuthUser = (data: Session) => {
	sessionStorage.setItem(authSessionKey, JSON.stringify(data));
};
export const deleteAuthUser = () => {
	sessionStorage.removeItem(authSessionKey);
};
