import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import useFileUploader from './useFileUploader';

export type FileType = File & {
	preview?: string;
	formattedSize?: string;
};

type FileUploaderProps = {
	name?: string;
	onFileUpload?: (files: FileType[]) => void;
	showPreview?: boolean;
	maxSize?: number;
	maxFiles?: number;
	invalid?: Boolean;
};

const FileUploader = ({
	name,
	showPreview = true,
	onFileUpload,
	maxSize,
	maxFiles,
	invalid,
}: FileUploaderProps) => {
	const { selectedFiles, handleAcceptedFiles, removeFile } = useFileUploader(
		showPreview,
		maxFiles
	);

	return (
		<>
			{maxFiles !== undefined && selectedFiles.length < maxFiles && (
				<Dropzone
					onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles, onFileUpload)}
					maxSize={maxSize ? maxSize * 1024 * 1024 : 1048576}
					maxFiles={maxFiles}
				>
					{({ getRootProps, getInputProps }) => (
						<div
							id={name}
							className={invalid ? 'dropzone is-invalid' : 'dropzone'}
							style={invalid ? { borderColor: 'red' } : {}}
						>
							<div className="dz-message needsclick" {...getRootProps()}>
								<input {...getInputProps()} />
								<i className="h1 text-muted ri-upload-cloud-2-line"></i>
								<h4 style={invalid ? { color: 'red' } : {}}>
									Arraste arquivos aqui ou clique para selecionar
								</h4>
								{maxSize && (
									<span className="text-muted font-13">
										Máximo de {maxSize}MB por arquivo
									</span>
								)}
							</div>
						</div>
					)}
				</Dropzone>
			)}

			{showPreview && selectedFiles.length > 0 && (
				<div
					className={'dropzone-previews mt-3 ' + (invalid ? 'is-invalid' : '')}
					id="uploadPreviewTemplate"
				>
					{(selectedFiles || []).map((f, i) => {
						return (
							<Card
								className={
									'mt-1 mb-0 shadow-none border ' +
									(invalid ? 'border-invalid' : '')
								}
								key={i + '-file'}
							>
								<div className="p-2">
									<Row className="align-items-center">
										{f.preview && (
											<Col className="col-auto">
												<img
													data-dz-thumbnail=""
													className="avatar-sm rounded bg-light"
													alt={f.name}
													src={f.preview}
												/>
											</Col>
										)}
										{!f.preview && (
											<Col className="col-auto">
												<div className="avatar-sm">
													<span className="avatar-title bg-secondary rounded">
														{f.type.split('/')[0]}
													</span>
												</div>
											</Col>
										)}
										<Col className="ps-0">
											<Link to="" className="text-muted fw-bold">
												{f.name}
											</Link>
											<p className="mb-0">
												<strong>{f.formattedSize}</strong>
											</p>
										</Col>
										<Col className="text-end">
											<Link
												to=""
												className="btn btn-link btn-lg text-muted shadow-none"
											>
												<i
													className="ri-close-line"
													onClick={() => removeFile(f, onFileUpload)}
												></i>
											</Link>
										</Col>
									</Row>
								</div>
							</Card>
						);
					})}
				</div>
			)}
		</>
	);
};

export { FileUploader };
