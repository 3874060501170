import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import i18n, { isValidLanguage, Languages } from '@/common/languages/i18n';
import { Theme, defaultTheme } from '@/config/menu';

type TThemeContext = {
	changeLanguage: (lang: string) => void;
	currentLanguage: string;
	settings: Theme;
	updateSettings: (newSettings: any) => void;
	updateLayout: (newLayout: any) => void;
	updateTopbar: (newTopbar: any) => void;
	updateSidebar: (newSidebar: any) => void;
};
const ThemeContext = createContext<TThemeContext | undefined>(undefined);

export function useThemeContext() {
	const context = useContext(ThemeContext);
	if (context === undefined) {
		throw new Error('useThemeContext must be used within an ThemeProvider');
	}
	return context;
}

export function ThemeProvider({ children }: { children: ReactNode }) {
	const [currentLanguage, setCurrentLanguage] = useState(Languages.EN);

	const [settings, setSettings] = useState<Theme>(defaultTheme);

	const changeLanguage = useCallback((lang: string) => {
		if (isValidLanguage(lang)) {
			i18n.changeLanguage(lang);
			setCurrentLanguage(lang);
		}
	}, []);

	const updateSettings = useCallback(
		(newSettings: any) => {
			setSettings((prev) => ({ ...(prev ?? {}), ...(newSettings ?? {}) }));
		},
		[setSettings]
	);

	const updateLayout = useCallback(
		(newLayout: any) => {
			setSettings((prev) => ({
				...(prev ?? {}),
				layout: { ...(prev ?? {}).layout, ...(newLayout ?? {}) },
			}));
		},
		[setSettings]
	);

	const updateTopbar = useCallback(
		(newTopbar: any) => {
			setSettings((prev) => ({
				...(prev ?? {}),
				topbar: { ...(prev ?? {}).topbar, ...(newTopbar ?? {}) },
			}));
		},
		[setSettings]
	);

	const updateSidebar = useCallback(
		(newSidebar: any) => {
			setSettings((prev) => ({
				...(prev ?? {}),
				sidebar: { ...(prev ?? {}).sidebar, ...(newSidebar ?? {}) },
			}));
		},
		[setSettings]
	);

	return (
		<ThemeContext.Provider
			value={{
				changeLanguage,
				currentLanguage,
				settings,
				updateSettings,
				updateLayout,
				updateTopbar,
				updateSidebar,
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
}
