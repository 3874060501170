import * as i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import pt_BR from './locales/pt-BR.json';

export const Languages = {
	EN: 'en',
	ES: 'es',
	FR: 'fr',
	PT_BR: 'pt_BR',
};

export const isValidLanguage = (lang: string) => Object.values(Languages).includes(lang);

i18n.use(initReactI18next).init({
	lng: Languages.PT_BR,
	resources: {
		[Languages.EN]: { translation: en },
		[Languages.ES]: { translation: es },
		[Languages.FR]: { translation: fr },
		[Languages.PT_BR]: { translation: pt_BR },
	},
});

export default i18n;
