import axios from 'axios';
import Endpoints from './endpoints';
import config from '@/config/environment';

export const HttpClient = () => {
	const endpoints = Endpoints;
	const _errorHandler = (error: any) => Promise.resolve(error.response || error);

	const _httpClient = axios.create({
		baseURL: config.API_URL,
		timeout: 60000,
		headers: {
			'Content-Type': 'application/json',
			Authorization: localStorage.getItem('access_token')
				? 'Bearer ' + localStorage.getItem('access_token')
				: null,
		},
	});

	_httpClient.interceptors.response.use((response) => {
		return response;
	}, _errorHandler);

	const getFile = (url: string, params: any) => {
		let response;
		if (params) {
			var queryString = params
				? Object.keys(params)
						.map((key) => key + '=' + params[key])
						.join('&')
				: '';
			response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
		} else {
			response = axios.get(`${url}`, { responseType: 'blob' });
		}
		return response;
	};

	const getMultiple = (urls: string, params: any) => {
		const reqs = [];
		let queryString = '';
		if (params) {
			queryString = params
				? Object.keys(params)
						.map((key) => key + '=' + params[key])
						.join('&')
				: '';
		}

		for (const url of urls) {
			reqs.push(axios.get(`${url}?${queryString}`));
		}
		return axios.all(reqs);
	};

	const postWithFile = (url: string, data: any) => {
		const formData = new FormData();
		for (const k in data) {
			formData.append(k, data[k]);
		}

		const config: any = {
			headers: {
				...axios.defaults.headers,
				'content-type': 'multipart/form-data',
			},
		};
		return axios.post(url, formData, config);
	};

	const putWithFile = (url: string, data: any) => {
		const formData = new FormData();
		for (const k in data) {
			formData.append(k, data[k]);
		}

		const config: any = {
			headers: {
				...axios.defaults.headers,
				'content-type': 'multipart/form-data',
			},
		};
		return axios.patch(url, formData, config);
	};

	return {
		endpoints,
		getFile,
		getMultiple,
		postWithFile,
		putWithFile,
		get: async (url: string, config = {}) => _httpClient.get(url, config),
		post: async (url: string, data: any, config = {}) => _httpClient.post(url, data, config),
		patch: async (url: string, data: any, config = {}) => _httpClient.patch(url, data, config),
		put: async (url: string, config = {}) => _httpClient.put(url, config),
		delete: async (url: string, config = {}) => _httpClient.delete(url, config),
		client: _httpClient,
	};
};
